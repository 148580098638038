import React, { useEffect } from "react";
import { Link } from 'gatsby'
import { Wrapper } from '../components/site/Wrapper';
import { Grid, Card, Image, Text, Badge, Button, Group, AspectRatio } from '@mantine/core';
import { useNavigate } from "@gatsbyjs/reach-router";
import ShortQR from '../components/ShortQR';
import { useMediaQuery } from "@mantine/hooks";
import Ad from "../components/site/Ad";
import * as ROUTES from '../constants/routes.constants';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';





export const Category = ({ pageContext, limit, ...props }) => {
    // useEffect(() => {
    //     console.log('props', pageContext, props);
    // }, []);
    const { meta: { puzzlePage, bookTitle,  buyBookUrl, bookImage, bookSlug, shortUrl, bookTitle: title, puzzleKeyImage, puzzleData } } = pageContext;

    // console.log(pageContext);
    const is320 = useMediaQuery('(min-width: 320px)');
    const is475 = useMediaQuery('(min-width: 475px)');
    const is775 = useMediaQuery('(min-width: 775px)');

    const words = puzzleData?.words?.sort((word1, word2)=> word1?.localeCompare(word2, {ignorePunctuation: true}) || []);
    const hiddenWords = puzzleData?.hiddenWords?.sort((word1, word2)=> word1?.localeCompare(word2, {ignorePunctuation: true}) || []);
    const buyLinkEnabled = (buyBookUrl?.length);
    return (

            <div className="px-2">
                        <div>
      <nav aria-label="breadcrumb" className="d-flex flex-row">
            <ol className="breadcrumb my-1">
                <li className="link align-center" >
                <Link to={`${ROUTES.WORDSEARCH_KEYS}${bookSlug}`}>
                        <KeyboardArrowLeftIcon />
                        <span>
                        {bookTitle}
                        </span>
                    </Link>
                </li>
            </ol>
            </nav>

                <div className="d-flex flex-row my-3">
                    <img src={bookImage} className="float-start" alt="Book Cover"/>
                    <div className="px-3">
                    <h1 className="text-uppercase">{puzzleData?.title}</h1>
                    {puzzlePage && <Text size="sm" className="my-2">
                        Puzzle on page {puzzlePage}
                    </Text>}
                    <a href={buyBookUrl} className={`btn btn-link px-0 ${buyLinkEnabled ? '': 'disabled'}`}>
                        {buyLinkEnabled ? "Buy on Amazon": 'Coming Soon'}
                    </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-7">

                    <img src={puzzleKeyImage} alt={puzzleData?.title} className="img-fluid"/>


                    <div>
                <Ad />
                </div>

                </div>
                
                
                <div className="col-12 col-lg-5">
                
                    {words?.length && <Group position="left" mt="md" mb="xs">
                        <h2>Puzzle Words</h2>
                        <div className="container wrap">
                            <div className="row">
                                {words.map(word => <div className="col-6 text-uppercase" key={word}>
                                    {word}</div>
                                )}</div>
                        </div>
                    </Group>}

                    {hiddenWords?.length ? <Group position="left" mt="md" mb="xs">
                        <h2>Secret Six Words</h2>
                        <div className="container wrap">
                            <div className="row">
                                {hiddenWords?.map(word => <div className="col-6 text-uppercase">
                                    {word}</div>
                                )}</div>
                        </div>
                    </Group> : null}

                </div>
            </div>
        </div>
    )
}

const BookKey = ({ pageContext, ...props }) => {
    return <Wrapper current={pageContext.node.name}>
        <Category pageContext={pageContext}
            {...props} />
    </Wrapper>
}

export default BookKey